import { Route, Routes } from 'react-router-dom';

import { Layout } from './pages/Layout.jsx';
import { Main } from './pages/Main.jsx';
import { Contacts } from './pages/Contacts.jsx';
import { Requisites } from './pages/Requisites.jsx';
import { Privacy } from './pages/Privacy.jsx';
import { PageNotFound } from './pages/PageNotFound.jsx';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="requisites" element={<Requisites />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
