import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div>
      <p className="font-bold">
        © {new Date().getFullYear()} Берёзка
        <br />
        Все права защищены
        <br />
      </p>
      <div
        className="tooltip text-xs text-justify font-normal"
        data-tip="Сайт использует файлы «cookie» с целью персонализации сервисов и повышения удобства пользования веб-сайтом."
      >
        <Link to="/privacy" className="link link-hover font-normal">
          Политика конфиденциальности
        </Link>
      </div>
    </div>
  );
};
