import React from 'react';
import { Link } from 'react-router-dom';

import { Menu } from './Menu.jsx';

export const Navbar = () => {
  return (
    <div className="w-full navbar bg-base-100 sticky top-0 z-50">
      <div className="flex-none lg:hidden">
        <label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-6 h-6 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </label>
      </div>
      <div className="flex-1 px-2 mx-2 place-content-end lg:place-content-start">
        <Link to="/">
          <img
            className="h-[20px]"
            src="berezka_logo_1123x794.svg"
            alt="Logo"
          />
        </Link>
      </div>
      <div className="flex-none hidden lg:block">
        <ul className="menu menu-horizontal">
          {/* <!-- Navbar menu content here --> */}
          <Menu />
        </ul>
      </div>
    </div>
  );
};
