import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Requisites = () => {
  return (
    <>
      <Helmet>
        <title>Реквизиты | ООО «Берёзка»</title>
        <meta
          name="description"
          content="Реквизиты ораганизации Берёзка - осуществляющей деятельность в области ИТ."
        />
        <meta property="og:title" content="Реквизиты | ООО «Берёзка" />
        <meta property="og:url" content="https://berezka.ooo/requisites/" />
        <meta
          property="og:image"
          content="https://berezka.ooo/berezka_logo_1123x794.svg"
        />
        <link rel="canonical" href="https://berezka.ooo/requisites/" />
      </Helmet>
      <div className="p-5">
        <div className="card bg-base-100">
          <div className="card-body items-center text-center">
            <h1 className="card-title font-extrabold">Реквизиты</h1>
          </div>
          <div className="card-body items-center shadow-2xl">
            <div className="overflow-x-auto">
              <table className="table-compact w-full">
                <tbody>
                  <tr>
                    <td className="text-right">Полное наименование</td>
                    <td className="text-left">
                      Общество с ограниченной ответственностью «Берёзка»
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Сокращенное наименование</td>
                    <td className="text-left">ООО «Берёзка»</td>
                  </tr>
                  <tr>
                    <td className="text-right">Адрес (место нахождения)</td>
                    <td className="text-left">
                      Россия, г. Ярославль, ул. 8 Марта, д. 2, кв. 10
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Почтовый адрес</td>
                    <td className="text-left">
                      150002, Россия, г. Ярославль, ул. 8 Марта, д. 2, кв. 10
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Сайт</td>
                    <td className="text-left">
                      <Link
                        className="link link-hover"
                        to="https://berezka.ooo"
                      >
                        {process.env.REACT_APP_URL}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Электронная почта</td>
                    <td className="text-left">
                      info@{process.env.REACT_APP_DOMAIN}
                      {/* <Link
                        className="link link-hover"
                        to="mailto:info@berezka.ooo"
                      >
                        info@{process.env.REACT_APP_DOMAIN}
                      </Link> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Телефон</td>
                    <td className="text-left">
                      +7 (910) 6623212
                      {/* <Link className="link link-hover" to="tel:+79106623212">
                        +7 (910) 6623212
                      </Link> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">ИНН</td>
                    <td className="text-left">7604376859</td>
                  </tr>
                  <tr>
                    <td className="text-right">КПП</td>
                    <td className="text-left">760401001</td>
                  </tr>
                  <tr>
                    <td className="text-right">ОГРН</td>
                    <td className="text-left">1217600009923</td>
                  </tr>
                  <tr>
                    <td className="text-right">Основной ОКВЭД</td>
                    <td className="text-left">62.02</td>
                  </tr>
                  <tr>
                    <td className="text-right">Система налогообложения</td>
                    <td className="text-left">УСНО</td>
                  </tr>
                  <tr>
                    <td className="text-right">Банк</td>
                    <td className="text-left">АО «АЛЬФА-БАНК»</td>
                  </tr>
                  <tr>
                    <td className="text-right">БИК банка</td>
                    <td className="text-left">044525593</td>
                  </tr>
                  <tr>
                    <td className="text-right">Корреспонденский счёт банка</td>
                    <td className="text-left">30101810200000000593</td>
                  </tr>
                  <tr>
                    <td className="text-right">Номер счёт в банке</td>
                    <td className="text-left">40702810902910011253</td>
                  </tr>
                  <tr>
                    <td className="text-right">Оператор ЭДО</td>
                    <td className="text-left">ООО «Компания «Тензор»</td>
                  </tr>
                  <tr>
                    <td className="text-right">Идентификатор ЭДО</td>
                    <td className="text-left">
                      2BE61268b2a8e214439a7a4f3aac483ce0f
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
