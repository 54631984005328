import $api from '../http/axios.js';

const sendLead = async (leadData) => {
  console.log(leadData);
  const response = await $api.post('/lead', leadData);
  console.log(response);
  return response.data;
};

const leadService = {
  sendLead,
};

export default leadService;
