import React from 'react';

import { Footer } from '../components/Footer.jsx';
import { Outlet } from 'react-router-dom';
import { DrawerSide } from '../components/DrawerSide.jsx';
import { Navbar } from '../components/Navbar.jsx';

export const Layout = () => {
  return (
    <div className="drawer">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="w-[100%] max-w-screen-lg mx-auto">
          {/* <!-- Navbar --> */}
          <Navbar />
          {/* <!-- Page content here --> */}
          <Outlet />
          <footer className="footer footer-center p-5 pt-0 hidden lg:block">
            <Footer />
          </footer>
        </div>
      </div>
      <DrawerSide />
    </div>
  );
};
