import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LeadForm } from '../components/LeadForm.jsx';

export const Main = () => {
  return (
    <>
      <Helmet>
        <title>
          Сопровождение ИТ-инфраструктуры в Ярославле | ООО «Берёзка»
        </title>
        <meta
          name="description"
          content="Берёзка - организация, осуществляющая деятельность в области информационных технологий. Внедрение, доработка и сопровождение ИТ-сервисов."
        />
        <meta
          property="og:title"
          content="Сопровождение ИТ-инфраструктуры в Ярославле | ООО «Берёзка"
        />
        <meta property="og:url" content={process.env.REACT_APP_URL} />
        <meta
          property="og:image"
          content="https://berezka.ooo/berezka_logo_1123x794.svg"
        />
        <link rel="canonical" href="https://berezka.ooo/" />
      </Helmet>
      <div className="p-5">
        <h1 className=" text-4xl md:text-5xl text-center font-bold">
          ИТ-АУТСОРСИНГ
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:shadow-2xl pl-5 pr-5">
        <div className="grid grid-cols-1 gap-y-5 pb-5 md:pr-2">
          <div className="card bg-secondary text-primary-content text-justify">
            <div className="card-body">
              <div className="alert alert-success p-1 bg-gradient-to-r from-black">
                <div>
                  <span className="font-bold">СОПРОВОЖДАЕМ</span>
                </div>
              </div>
              <p>
                Осуществляем сопровождение ИТ-инфраструктуры имеющейся в
                Компании и техническую поддержку пользователей работающих в
                рамках этой инфраструктуры.
              </p>
            </div>
          </div>
          <div className="card bg-secondary text-primary-content text-justify">
            <div className="card-body">
              <div className="alert alert-success p-1 bg-gradient-to-r from-black">
                <div>
                  <span className="font-bold">ДОРАБАТЫВАЕМ</span>
                </div>
              </div>
              <p>
                Дорабатываем имеющиеся в Компании информационные системы с целью
                повышения их эффективности, при изменении потребностей бизнеса.
              </p>
            </div>
          </div>
          <div className="card bg-secondary text-primary-content text-justify">
            <div className="card-body">
              <div className="alert alert-success p-1 bg-gradient-to-r from-black">
                <div>
                  <span className="font-bold">ВНЕДРЯЕМ</span>
                </div>
              </div>
              <p>
                Оказываем услуги по анализу, проектированию, пуско-наладке
                информационных систем различного применения и сложности.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-4 md:pl-8 md:pr-5 md:pt-5">
            <div className="chat chat-start">
              <div className="chat-bubble">Есть возможность попробовать?</div>
            </div>
            <div className="chat chat-start">
              <div className="chat-bubble">
                Как узнать подробности об услугах?
              </div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble">
                Оставьте контакты, опишите потребность и мы Вам перезвоним в
                ближайшее время.
              </div>
            </div>
          </div>
          <LeadForm />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-5 gap-x-5 p-5 pb-0">
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body text-justify">
            <p>
              Ярославская ИТ-компания "Берёзка" осуществляет сопровождение
              ИТ-инфраструктур малых и средних организаций, для которых
              информационные технологии не являеются профильными. География
              обслуживания: Ярославль и смежные области.
            </p>
            <p>
              Основным отличием обслуживания нашей Компанией от большинства
              других в сфере ИТ-аутсорсинга в том, что клиенты платят нам за то,
              сколько времени их ИТ-сервисы работали исправно, а не за то
              сколько времени потратили наши инженеры.
            </p>
            <p>
              Мы работаем на результат и делаем всё необходимое, чтобы наши
              клиенты не отвлекались на задачи связанные с непрофильным для них
              ИТ-направлением.
            </p>
            <p>
              Перед началом обслуживания, мы с ответственным сотрудником со
              стороны заказчика производим обследование как инфраструктуры, так
              и систем в неё входящих. После этого определяются бизнес-сервисы
              использующиеся сотрудниками, их критичность для жизнедеятельности
              Компании, а также метрики по которым измеряется их
              работоспособность. Результатом этого обследования является
              соглашение об уровне обслуживания, в котором определены гарантии
              работоспобности выявленных сервисов: время плановой
              работоспособности, сроки разрешения инцидентов и выполнения
              запросов пользователей, а также штрафы за несоблюдение целевых
              показателей.
            </p>
            <p>
              После создания соглашения об уровне оказываемых услуг,
              составляется и заключается договор на сопровождение, неотъемлемой
              частью которого является в том числе, соглашение о неразглашении
              конфиденциальной информации. В договоре определяется максимальная
              ежемесячная плата за услуги при соблюдении условий соглашения об
              уровне оказываемых услуг.
            </p>
            <p>
              Для соблюдения договоренностей с заказчиком, инженерами нашей
              Компании проводятся регламентные операции по каждой информационной
              системе составляющей бизнес-сервис, применяются оптимальные
              конфигурации конечных систем и их элементов, передовые средства по
              мониторингу, автоматизации стандартных операций, средств
              резервного копирования и восстановления.
            </p>
            <p>
              Осуществлению прозрачной деятельности в определении конечных
              показателей работы бизнес-сервисов способствует разработанная
              нашей Компанией информационная система, которая позволяет
              отслеживать, определенные в соглашении об уровне обслуживания,
              метрики и создавать ежемесячные отчёты о соответствии целевых
              результатов с фактическими. На основании этих отчётов определяется
              итоговое вознаграждение за выполненную работу, которое при
              соблюдении условий соглашения составляет максимальную определенную
              ежемесячную цену. При отклонении фактических показателей от
              целевых, в сторону ухудшения работы информационных систем
              исполнителю выплачивается сумма за вычетом штрафов определенных в
              соглашении.
            </p>
            <div className="alert alert-success shadow-lg">
              <div>
                <span>
                  Таким образом каждый из участников процесса заинтересован в
                  конечном результате - в исправно работающей ИТ-инфраструктуре.
                </span>
              </div>
            </div>
            <p>
              Для определения конечной ежемесячной стоимости, как для новых, так
              и существующих клиентов, требуются основные данные: время работы
              организации, работает ли Компания в выходные и праздничные дни,
              сколько офисов и в каких городах имеет организация, количество
              сотрудников, требуемое время восстановления при аварийных
              ситуациях. Эти параметры, а так же количество и качество элементов
              информационных систем обсуждается с каждым заказчиком
              индивидуально, и стоимость услуг может варьироваться от 20 до 100
              тысяч рублей.
            </p>
            <p>
              Ниже представлены сервисы и продукты, которые обслуживает наша
              Компания.
            </p>
            <div className="alert alert-success shadow-lg">
              <div>
                <span>
                  Если у Вас возникли вопросы или предложения, Вы всегда можете
                  воспользоваться телефоном и адресом электронной почты
                  указанных в разделе <Link to="/contacts">Контакты</Link>, или
                  оставить Ваши вопросы в форме обратной связи вверху страницы.
                  Мы открыты и готовы к сотрудничеству!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2 lg:grid-cols-3 p-5 pb-0">
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Рабочие места</h2>
            <p>
              Персональные компьютеры, ноутбуки, моноблоки, переносные терминалы
              и периферийное оборудование. Клиентские операционные системы
              (Microsoft, Linux). Разнообразное клиентское программное
              обеспечение (офисные пакеты, браузеры, программы криптографии,
              графические программы и т. п.). Всё то что имеется у сотрудника на
              рабочем месте и с чем он работает каждый день.
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Серверы</h2>
            <p>
              Аппаратные (Lenovo, HP, Supermicro) и виртуальные (Microsoft,
              VMware, KVM) серверы. Серверные операционные системы (Microsoft,
              Linux). Аппаратные (Lenovo/IBM, HP) и программные (Microsoft,
              Linux) системы хранения данных. Аппаратные и виртуальные кластеры
              высокой доступности.
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Сети</h2>
            <p>
              Активное и пассивное сетевое оборудование (Cisco, HPE, Mikrotik,
              D-Link). Межсетевые экраны, маршрутизаторы, беспроводные сети,
              прямые и обратные прокси. Объединение сетей филиалов и офисов с
              помощью VPN. Безопасный удалённый доступ к корпоративной сети и
              корпоративным сервисам.
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Базы данных</h2>
            <p>
              Системы управления базами данных (Microsoft SQL Server,
              PostgreSQL, MySQL, MariaDB, MongoDB).
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Информационные системы</h2>
            <p>
              Системы управления бухгалтерией, торговлей, складом, электронной
              отчётностью, электронного внешнего и внутреннего документооборота
              (1С:Предприятие, СБиС++, СБИС, Битрикс, Dynamics 365, SharePoint).
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Коммуникации</h2>
            <p>
              Системы корпоративной электронной почты (Microsoft Exchange Server
              и Exchange Online), а так же Яндекс.Почта и VK WorkSpace. Системы
              телефонии на базе Asterisk и СБИС, а так же системы коммуникаций
              реального времени на базе Microsoft Skype for Business Server и
              Microsoft Teams.
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Резервное копирование</h2>
            <p>
              Системы резервного копирования корпоративного уровня Microsoft
              System Center Data Protection Manager и Veeam Backup &
              Replication, а так же продукты включенные в операционные системы
              (Microsoft, Linux) и другие продукты (pgdamp)
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Видеонаблюдение</h2>
            <p>
              Системы видеонаблюдения построенные на видеорегистраторах:
              Hikvision, Atlas, Satvision, TRASSIR.
            </p>
          </div>
        </div>
        <div className="card bg-secondary text-primary-content">
          <div className="card-body text-justify">
            <h2 className="card-title">Облачные платформы</h2>
            <p>
              ИТ-системы построенные на элементах облачных платформ: Microsoft
              365, Microsoft Azure, SberCloud, Yandex Cloud, VK Cloud.
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 p-5 pb-0">
        <div className="card w-full bg-primary text-primary-content">
          <div className="card-body text-6xl text-center font-bold">
            <p>Чем мы можем быть полезны?</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2 lg:grid-cols-3 p-5">
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body text-justify">
            <h2 className="card-title">Первая линия поддержки</h2>
            <p>
              У Вас в штате имеется ИТ-специалист который определяет стратегию
              развития ИТ в Компании, занимается обслуживанием серверов и
              информационных систем, но у него нет времени заниматься вопросами
              и проблемами пользователей, обслуживанием персональных
              компьютеров.
            </p>
            <div className="alert alert-success shadow-lg">
              <div>
                <span>Мы возьмём на себя эти задачи!</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body text-justify">
            <h2 className="card-title">Вторая линия поддержки</h2>
            <p>
              В штате есть ИТ-специалист, который отлично ладит с пользователями
              - решает их проблемы, помогает в работе с персональными
              компьютерами, но либо не желает или не имеет квалификации по
              обслуживанию серверного аппаратного и программного обеспечения, не
              разбирается в информационных системах, а Компания всё больше и
              больше нуждается в решении высокоуровневых или специализированных
              задач.
            </p>
            <div className="alert alert-success shadow-lg">
              <div>
                <span>
                  Мы подхватим такие задачи и сделаем ИТ-инфраструктуру надёжной
                  и самодостаточной!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body text-justify">
            <h2 className="card-title">Комплексное сопровождение</h2>
            <p>
              Вы расстаётесь с системным администратором или с целым отдёлом ИТ?
              Или Ваша Компания только начинает свой путь?
            </p>
            <div className="alert alert-success shadow-lg">
              <div>
                <span>
                  Мы в кратчайшие сроки примем дела, проведём обследование и
                  вольёмся в бизнес-процессы для удовлетворения потребностей
                  Компании в сфере ИТ.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
