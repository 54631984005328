import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Contacts = () => {
  return (
    <>
      <Helmet>
        <title>Контакты | ООО «Берёзка»</title>
        <meta
          name="description"
          content="Контакты ораганизации Берёзка - осуществляющей деятельность в области ИТ."
        />
        <meta property="og:title" content="Контакты | ООО «Берёзка" />
        <meta property="og:url" content="https://berezka.ooo/contacts/" />
        <meta
          property="og:image"
          content="https://berezka.ooo/berezka_logo_1123x794.svg"
        />
        <link rel="canonical" href="https://berezka.ooo/contacts/" />
      </Helmet>
      <div className="p-5">
        <div className="card bg-base-100">
          <div className="card-body items-center text-center">
            <h1 className="card-title font-extrabold">Контакты</h1>
          </div>
          <div
            className="card-body items-center shadow-2xl"
            itemScope
            itemType="http://schema.org/Organization"
          >
            <div className="overflow-x-auto">
              <table className="table-compact w-full">
                <tbody>
                  <tr>
                    <td className="text-right">Название</td>
                    <td className="text-left" itemProp="name">
                      Берёзка
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Сайт</td>
                    <td className="text-left" itemProp="url">
                      <Link
                        className="link link-hover"
                        to="https://berezka.ooo"
                      >
                        {process.env.REACT_APP_URL}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Почта</td>
                    <td className="text-left" itemProp="email">
                      info@{process.env.REACT_APP_DOMAIN}
                      {/* <Link
                        className="link link-hover"
                        to="mailto:info@berezka.ooo"
                      >
                        info@{process.env.REACT_APP_DOMAIN}
                      </Link> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Телефон</td>
                    <td className="text-left" itemProp="telephone">
                      +7 (910) 6623212
                      {/* <Link className="link link-hover" to="tel:+79106623212">
                        +7 (910) 6623212
                      </Link> */}
                    </td>
                  </tr>
                  <tr
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <td className="text-right">Улица</td>
                    <td className="text-left" itemProp="streetAddress">
                      8 Марта, д. 2, кв. 10
                    </td>
                  </tr>
                  <tr
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <td className="text-right">Город</td>
                    <td className="text-left" itemProp="addressLocality">
                      Ярославль
                    </td>
                  </tr>
                  <tr
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <td className="text-right">Регион</td>
                    <td className="text-left" itemProp="addressRegion">
                      Ярославская область
                    </td>
                  </tr>
                  <tr
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <td className="text-right">Страна</td>
                    <td className="text-left" itemProp="addressCountry">
                      Россия
                    </td>
                  </tr>
                  <tr
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <td className="text-right">Индекс</td>
                    <td className="text-left" itemProp="postalCode">
                      150002
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
