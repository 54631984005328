import React from 'react';
import { Helmet } from 'react-helmet';

export const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>Страница не найдена | ООО «Берёзка»</title>
        <meta
          name="description"
          content="Реквизиты ораганизации Берёзка - осуществляющей деятельность в области ИТ."
        />
        <meta
          property="og:title"
          content="Страница не найдена | ООО «Берёзка"
        />
        <meta property="og:url" content="https://berezka.ooo/404/" />
        <meta
          property="og:image"
          content="https://berezka.ooo/berezka_logo_1123x794.svg"
        />
        <link rel="canonical" href="https://berezka.ooo/404/" />
      </Helmet>
      <div className="p-5">
        <div className="card bg-base-100">
          <div className="card-body items-center text-center">
            <h1 className="card-title font-extrabold">Страница не найдена</h1>
          </div>
        </div>
      </div>
    </>
  );
};
