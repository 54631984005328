import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import leadService from '../services/lead-service.js';
import { Link } from 'react-router-dom';

export const LeadForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const sendFormHandler = async (leadData) => {
    setIsLoading(true);
    const lead = await leadService.sendLead(leadData);
    console.log(lead);
    setIsLoading(false);
    setIsLoaded(true);
    reset();
  };

  return (
    <>
      <div className="card flex-shrink-0 w-full bg-base-100">
        <form
          className="card-body p-0 md:pr-0 md:pl-3 md:pb-5 pt-5 gap-y-4"
          onSubmit={handleSubmit(sendFormHandler)}
        >
          <div className="indicator w-full">
            {errors?.name && (
              <span className="indicator-item indicator-center badge badge-warning badge-sm text-black font-bold">
                {errors?.name?.message || 'Введены некорректные данные'}
              </span>
            )}
            <div className="form-control w-full">
              <input
                className="input input-bordered"
                type="text"
                placeholder="Имя"
                {...register('name', {
                  required: 'Обязательные данные',
                  minLength: {
                    value: 2,
                    message: 'Не менее 2 и не более 15 букв на русском',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Не менее 2 и не более 15 букв на русском',
                  },
                  pattern: {
                    value: /([а-я]+)/,
                    message: 'Не менее 2 и не более 15 букв на русском',
                  },
                })}
              />
            </div>
          </div>
          <div className="indicator w-full">
            {errors?.telephone && (
              <span className="indicator-item indicator-center badge badge-warning badge-sm text-black font-bold">
                {errors?.telephone?.message || 'Введены некорректные данные'}
              </span>
            )}
            <div className="form-control w-full">
              <input
                className="input input-bordered"
                type="tel"
                placeholder="Телефон"
                {...register('telephone', {
                  required: 'Обязательные данные',
                  pattern: {
                    value:
                      /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/,
                    message: 'Номер телефона начинающийся с +7 или 8',
                  },
                })}
              />
            </div>
          </div>
          <div className="indicator w-full">
            {errors?.email && (
              <span className="indicator-item indicator-center badge badge-warning badge-sm text-black font-bold">
                {errors?.email?.message || 'Введены некорректные данные'}
              </span>
            )}
            <div className="form-control w-full">
              <input
                className="input input-bordered"
                type="email"
                placeholder="Email"
                {...register('email', {
                  pattern: {
                    value:
                      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                    message: 'Некорректный формат email',
                  },
                })}
              />
            </div>
          </div>
          <div className="indicator w-full">
            {errors?.message && (
              <span className="indicator-item indicator-center badge badge-warning badge-sm text-black font-bold">
                {errors?.message?.message || 'Введены некорректные данные'}
              </span>
            )}
            <div className="form-control w-full">
              <textarea
                className="textarea textarea-bordered textarea-lg px-4 py-2"
                type="textarea"
                placeholder="Сообщение"
                {...register('message', {
                  required: 'Обязательные данные',
                  minLength: {
                    value: 2,
                    message: 'Не менее 2 и не более 400 букв и цифр',
                  },
                  maxLength: {
                    value: 400,
                    message: 'Не менее 2 и не более 400 букв и цифр',
                  },
                })}
              />
            </div>
          </div>
          <div className="form-control flex flex-row self-center">
            <input
              className="checkbox checkbox-xs"
              type="checkbox"
              {...register('acceptance', {
                required: 'Обязательные данные',
              })}
            />
            <p className="text-xs pl-2">Ознакомлен(а) с</p>
            <Link to="/privacy" className="link font-normal text-xs pl-1">
              Политикой конфиденциальности
            </Link>
          </div>
          <div className="form-control mt-0">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!isValid}
            >
              {isLoading ? (
                <progress className="progress progress-success w-56"></progress>
              ) : isLoaded ? (
                'отправлено'
              ) : (
                'отправить'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
