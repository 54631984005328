import React from 'react';

import { Menu } from './Menu.jsx';
import { Footer } from './Footer.jsx';

export const DrawerSide = () => {
  return (
    <div className="drawer-side">
      <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
      <div className="flex flex-col p-4 w-fit bg-base-100">
        {/* <!-- Sidebar content here --> */}
        <div className="avatar justify-center">
          <div className="rounded">
            <img src="berezka_logo_120x120.svg" alt="Logo" />
          </div>
        </div>
        <ul className="menu">
          <Menu />
        </ul>
        <footer className="footer footer-center p-2">
          <Footer />
        </footer>
      </div>
    </div>
  );
};
